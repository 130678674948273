import React from 'react';

// Import typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

import profilePic from './profile-pic.jpg';
import { rhythm } from '../utils/typography';

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={profilePic}
          alt={`Billy Kaplan`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <div>
          <p>
            Hi, I'm Billy. Welcome to my blog about programming, books, musings, etc.
          </p>
          <p>
            Recurser W1 '18. Former engineer at Amazon.
          </p>
          <p>Reach me at: billy1kaplan@gmail.com</p>
        </div>
        </div>
    );
  }
}

export default Bio;

/*
</p>

    <a href="https://github.com/billy1kaplan">
    [ Github ]
</a>
    </p>
*/
